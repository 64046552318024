<template>
  <div class="sld_invoice_address">
    <div class="container">
      <h3>{{ L['寄送信息管理'] }}</h3>

      <div class="box2 flex-row">
        <span class="word23" :class="{ active: tabIndex == 0 }" @click="changeTab(0)">{{L['寄送地址']}}</span>
        <span class="word23" :class="{ active: tabIndex == 1 }" @click="changeTab(1)">{{L['电子邮箱']}}</span>
      </div>

      <template v-if="tabIndex == 0">
        <div class="block2 flex-col" @click="openAddress">
          <span class="word24">{{L['新增地址']}}</span>
        </div>
        <template v-if="addressList.data.length > 0">
          <div class="wrap9 flex-col">
            <div class="section3 flex-row">
              <span class="txt11">{{L['收件人姓名']}}</span>
              <span class="txt11 word23">{{L['联系电话']}}</span>
              <span class="txt11 word24">{{L['地址']}}</span>
              <span class="txt11 word25">{{L['操作']}}</span>
            </div>
          </div>
          <div class="bd10 flex-col">
            <template v-for="(item, index) in addressList.data" :key="item.addressId">
              <div class="section6 flex-col">
                <div class="mod31 flex-row">
                  <span class="info12">{{ item.memberName }}</span>
                  <span class="info12 word26">{{ item.telMobile }}</span>
                  <span class="info12 info13">{{ item.addressAll }} {{ item.detailAddress }}</span>
                  <div class="mod32 flex-col" :class="{ active: item.isDefault == 1 }"
                    @click="setAuto(item.addressId, 1)">
                    <span class="word27">{{ item.isDefault == 1 ? L['默认地址'] : L['设为默认'] }}</span>
                  </div>
                  <span class="word27 word28" @click="edit(index, 1)">{{L['编辑']}}</span>
                  <span class="word27 txt19" @click="drop(item.addressId, 1)">{{L['删除']}}</span>
                </div>
              </div>
            </template>
            <el-pagination @current-change="handleCurrentChange" :currentPage="pageData.current"
              :page-size="pageData.pageSize" layout="prev, pager, next, jumper" :total="pageData.total"
              :hide-on-single-page="false" class="flex_row_end_center" style="margin-top:10px;"></el-pagination>
          </div>
        </template>
        <template v-else>
          <div class="wrap9 flex-col">
            <div class="section3 flex-row">
              <span class="txt11">{{L['收件人姓名']}}</span>
              <span class="word23">{{L['联系电话']}}</span>
              <span class="word24">{{L['地址']}}</span>
              <span class="word25">{{L['操作']}}</span>
            </div>
          </div>
          <div class="wrap10 flex-col">
            <div class="bd2 flex-col">
              <div class="section4 flex-col"></div>
              <div class="section5">
                <span class="info19">{{L['暂无地址，请']}}</span>
                <span class="info20" @click="openAddress">{{L['添加地址']}}</span>
              </div>
            </div>
          </div>
        </template>
      </template>
      <template v-else>
        <div class="block2 flex-col" @click="openEmail">
          <span class="word24">{{L['新增邮箱']}}</span>
        </div>
        <template v-if="emailList.data.length > 0">
          <div class="block5 flex-col">
            <div class="bd2 flex-row">
              <span class="txt12">{{L['电子邮箱']}}</span>
              <span class="word29">{{L['操作']}}</span>
            </div>
          </div>
          <template v-for="(item, index) in emailList.data" :key="item.emailId">
            <div class="box12 flex-col">
              <div class="wrap6 flex-row">
                <span class="word30">{{ item.email }}</span>
                <div class="wrap7 flex-col" :class="{ active: item.isDefault == 1 }"
                  @click="setAuto(item.emailId, 2, item.email)">
                  <span class="info12">{{ item.isDefault == 1 ? L['默认邮箱'] : L['设为默认'] }}</span>
                </div>
                <span class="info12 txt13" @click="edit(index, 2)">{{L['编辑']}}</span>
                <span class="info12 txt14" @click="drop(item.emailId, 2)">{{L['删除']}}</span>
              </div>
            </div>
          </template>
          <el-pagination @current-change="handleCurrentChange" :currentPage="pageData.current"
            :page-size="pageData.pageSize" layout="prev, pager, next, jumper" :total="pageData.total"
            :hide-on-single-page="false" class="flex_row_end_center" style="margin-top:10px;"></el-pagination>
        </template>
        <template v-else>
          <div class="block5 flex-col">
            <div class="bd2 flex-row">
              <span class="txt12">{{L['电子邮箱']}}</span>
              <span class="word29">{{L['操作']}}</span>
            </div>
          </div>
          <div class="main9 flex-col">
            <div class="bd4 flex-col">
              <div class="block22 flex-col"></div>
              <div class="block3">
                <span class="info7">{{L['暂无数据，请']}}</span>
                <span class="txt21" @click="openEmail">{{L['添加邮箱']}}</span>
              </div>
            </div>
          </div>
        </template>
      </template>

      <AddressOperation v-if="addDailog" @close="close" @refreshAddress="refreshAddress" :type="type"
        :addressInfo="addressInfo" areaMax="60" :headerTitle="L['添加寄送地址']" />

      <EmailOperation v-if="emailDailog" @closeEmail="closeEmail" @refreshEmail="refreshEmail" :type="type"
        :emailInfo="emailInfo"></EmailOperation>
    </div>
  </div>
</template>

<script>
import { reactive, ref, getCurrentInstance, onMounted } from "vue";
import AddressOperation from "@/components/InvoiceAddress.vue";
import EmailOperation from "@/components/InvoiceEmail.vue";
import { ElMessage, ElMessageBox } from "element-plus";
export default {
  name: "FinaceInvoiceAddress",
  components: {
    AddressOperation,
    EmailOperation,
  },
  setup() {
    const { proxy } = getCurrentInstance();
    const L = proxy.$getCurLanguage();
    const tabIndex = ref(0);
    const addressList = reactive({ data: [] });
    const emailList = reactive({ data: [] });
    const type = ref("add");
    const addressInfo = reactive({ data: [] });
    const emailInfo = reactive({ data: [] });
    const addDailog = ref(false);
    const emailDailog = ref(false);
    const isClick = ref(false);
    const pageData = reactive({
      current: 1,
      pageSize: 10,
      total: 0
    });

    const getAddressList = () => {
      let param = {
        current: pageData.current,
        pageSize: pageData.pageSize
      }
      proxy.$get("v3/member/front/memberInvoiceAddress/list", param).then((res) => {
        addressList.data = res.data.list;
        pageData.total = res.data.pagination.total;
      });
    };

    const getEmailList = () => {
      let param = {
        current: pageData.current,
        pageSize: pageData.pageSize
      }
      proxy.$get("v3/member/front/memberInvoiceEmail/list", param).then((res) => {
        emailList.data = res.data.list;
        pageData.total = res.data.pagination.total;
      });
    };

    const changeTab = (index) => {
      //切换导航栏
      if (tabIndex.value != index) {
        tabIndex.value = index;
        pageData.current = 1;
        if (index == 0) {
          getAddressList();
        } else {
          getEmailList();
        }
      }
    };

    //页数改变
    const handleCurrentChange = e => {
      pageData.current = Math.floor(e);
      if (tabIndex.value == 0) {
        getAddressList();
      } else {
        getEmailList();
      }
    };

    const setAuto = (id, operateType, info) => {
      if (isClick.value) {
        return;
      }
      isClick.value = true;
      if (operateType == 1) {
        let param = {
          addressId: id,
          isDefault: 1,
        };
        proxy
          .$post("v3/member/front/memberInvoiceAddress/update", param)
          .then((res) => {
            if (res.state == 200) {
              ElMessage.success(res.msg);
              getAddressList();
            } else {
              ElMessage(res.msg);
            }
            isClick.value = false;
          });
      } else {
        let param = {
          emailId: id,
          email: info,
          isDefault: 1,
        };
        proxy
          .$post("v3/member/front/memberInvoiceEmail/update", param)
          .then((res) => {
            if (res.state == 200) {
              ElMessage.success(res.msg);
              getEmailList();
            } else {
              ElMessage(res.msg);
            }
            isClick.value = false;
          });
      }
    };

    const edit = (index, operateType) => {
      type.value = "edit";
      if (operateType == 1) {
        addressInfo.data = addressList.data[index];
        addDailog.value = true;
      } else {
        emailInfo.data = emailList.data[index];
        emailDailog.value = true;
      }
    };

    const drop = (id, operateType) => {
      ElMessageBox.confirm(operateType == 1 ? L['确定删除该地址?'] : L['确定删除该邮箱?'], L['提示'], {
        confirmButtonText: L['确定'],
        cancelButtonText: L['取消'],
        type: 'warning',
      }).then(() => {
        if (isClick.value) {
          return;
        }
        isClick.value = true;
        if (operateType == 1) {
          let param = {
            addressIds: id,
          };
          proxy
            .$post("v3/member/front/memberInvoiceAddress/delete", param)
            .then((res) => {
              if (res.state == 200) {
                ElMessage.success(res.msg);
                getAddressList();
              } else {
                ElMessage(res.msg);
              }
              isClick.value = false;
            });
        } else {
          let param = {
            emailIds: id,
          };
          proxy
            .$post("v3/member/front/memberInvoiceEmail/delete", param)
            .then((res) => {
              if (res.state == 200) {
                ElMessage.success(res.msg);
                getEmailList();
              } else {
                ElMessage(res.msg);
              }
              isClick.value = false;
            });
        }
      }).catch(() => { })
    };

    const openAddress = () => {
      type.value = "add";
      addDailog.value = true;
    };

    const close = () => {
      addDailog.value = false;
    };

    const refreshAddress = () => {
      addDailog.value = false;
      getAddressList();
    };

    const openEmail = () => {
      type.value = "add";
      emailDailog.value = true;
    }
      ;
    const closeEmail = () => {
      emailDailog.value = false;
    };

    const refreshEmail = () => {
      emailDailog.value = false;
      getEmailList();
    };

    onMounted(() => {
      getAddressList();
    });

    return {
      L,
      tabIndex,
      addressList,
      emailList,
      changeTab,
      getAddressList,
      type,
      addressInfo,
      emailInfo,
      setAuto,
      edit,
      drop,
      addDailog,
      openAddress,
      close,
      refreshAddress,
      emailDailog,
      openEmail,
      closeEmail,
      refreshEmail,
      pageData,
      handleCurrentChange,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/finace/invoiceAddress.scss";
</style>